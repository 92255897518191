import DOMPurify from 'dompurify';
import { useMemo, ReactNode } from 'react';
import he from 'he';

export type SafeHTMLProps = {
  readonly unsafeHTML: string;
  readonly stripTags?: boolean;
  children: (string) => ReactNode;
};

export const SafeHTML: React.FC<SafeHTMLProps> = props => {
  const safe = useMemo(() => {
    return stripHtml(props.unsafeHTML, props.stripTags);
  }, [props.unsafeHTML, props.stripTags]);

  return <>{props.children(safe)}</>;
};

export const stripHtml = (unsafeHTML: string, stripTags: boolean, stripAll?: boolean) => {
  if (!unsafeHTML) {
    return '';
  }

  // What does this do? Well DOMPurify needs a window object.
  // The only realistic way to get that on node is using JSDOM
  // JSDOM breaks webpack, so this uses eval to import JSDOM
  // only when the code is running server side.
  let windowToUse: Window;
  if (typeof window === 'undefined') {
    const { JSDOM } = eval("require('jsdom')");
    windowToUse = new JSDOM('').window;
  } else {
    windowToUse = window;
  }
  const purify = DOMPurify(windowToUse);

  // If we want only text
  if (stripTags) {
    // For some reason the html code is amp encoded coming from hasura. `he` undoes that.
    // Then we convert the p tags to br because safari is odd.
    const val = purify
      .sanitize(he.decode(unsafeHTML), {
        ALLOWED_TAGS: stripAll ? [] : ['br', 'P'],
        KEEP_CONTENT: true
      })
      .replace(/<p[^>]*>/g, '')
      .replace(/<\/p>/g, '<br />');

    return val;
  }
  // For some reason the html code is amp encoded coming from hasura. `he` undoes that.
  return purify.sanitize(he.decode(unsafeHTML));
};
