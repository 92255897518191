import _Head from 'next/head';
import { useRouter } from 'next/router';
import { stripHtml } from './safe-html';

export type HeadProps = {
  title: string;
  description: string;
  url: string;
  img?: string;
};

export const Head: React.FC<HeadProps> = ({ title, description, img, url }) => {
  const router = useRouter();

  const desc =
    stripHtml(description, true, true) ||
    'VoyceMe is the home of creativity! Enjoy reading light novels, mangas, and webcomics across every genre! Want to join VoyceMe? Make an account and starting posting your series today!';
  return (
    <_Head>
      <title>{title ? `${title} | ` : ''}Voyce.Me</title>
      <meta name="description" content={desc} />
      <link rel="icon" href="/favicon.ico" />
      <meta property="og:title" content={`${title ? `${title} | ` : ''}Voyce.Me`} />
      <meta property="og:description" content={desc} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={ url } />
      {img ? <meta property="og:image" content={ img } /> : null}
      {img ? <meta property="twitter:image" content={ img } /> : null}
    </_Head>
  );
};
